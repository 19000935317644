import styled from "styled-components";

export const QuestionsCount = styled.span.attrs({
  className: "btn",
})`
  :hover,
  :active {
    cursor: help !important;
    color: inherit !important;
    background-color: inherit !important;
    border-color: var(--tblr-border-color) !important;
  }
`;
