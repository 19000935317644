import parse from "html-react-parser";

import { Container} from "./style"

const STORAGE_KEY = "hello_bar_ia_50_off";

const Message = () => {
  const canShowAlert = () => {
    return !Boolean(window.sessionStorage.getItem(STORAGE_KEY));
  };

  const onCloseAlertActions = () => {
    window.sessionStorage.setItem(STORAGE_KEY, "true");
  };

  if (!canShowAlert()) {
    return <></>;
  }

  return (
    <Container className="alert alert-important alert-dismissible fade show text-center mb-0" role="alert">
      <p className="d-inline text-dark">
        {parse(I18n.t("views.shared.hello_bar.message_html"))}
      </p>

      <a href="/ai/leadster" className="btn btn-primary ms-3 d-inline" rel="noopener">
        {I18n.t("views.shared.hello_bar.button")}
      </a>

      <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() => onCloseAlertActions()} />
    </Container>
  );
};

export default Message;
