import { useState, memo } from "react";

import useDinamycFields from "@/components/scripts/hooks/useDinamycFields";

import CloneBlockButton from "./ScriptEditorParts/CloneBlockButton";
import DeleteButton from "./ScriptEditorParts/DeleteButton";
import PermissionsGate from "@/components/permissions/PermissionsGate";
import RichTextEditor from "@/components/shared/RichTextEditor";

import { can } from "@/permissions-provider";
import { replaceTag } from "@/utils/helpers";

import setupEditor from "./ScriptEditorParts/setup-editor";

const PERMISSION_SCOPE = Object.freeze({
  action: "manage",
  subject: "script_flow",
});

const ScriptBlockSay = (props) => {
  const hasPermission = can(PERMISSION_SCOPE);
  const dynamicFields = useDinamycFields();

  const [say, setSay] = useState(props.definition.say);

  const handleEditorChange = (msg) => {
    if (!hasPermission) return;

    const dataReplacedBold = replaceTag(msg, "strong", "b");
    const dataReplacedItalic = replaceTag(dataReplacedBold, "em", "i");

    if (props.definition.say !== dataReplacedItalic) {
      setSay(msg);
      props.setDefinition({ ...props.definition, say: dataReplacedItalic });
    }
  };

  return (
    <div className="row gx-2">
      <div className="col">
        <div className="input-group">
          <span className="input-group-text bg-blue text-white">
            <i className="ti ti-message-dots me-2"></i>
            {I18n.t("manage_flow.script_editor.message")}
          </span>

          <PermissionsGate scope={PERMISSION_SCOPE}>
            <div className="form-control p-0 border-0">
              <RichTextEditor
                value={say}
                disabled={!hasPermission}
                inline={true}
                init={{
                  menubar: false,
                  placeholder: I18n.t("manage_flow.script_editor.type_a_message_here"),
                  plugins: "autolink link emoticons",
                  toolbar: "bold italic underline | link emoticons | autocompleteButton",
                  setup: (editor) => setupEditor(editor, dynamicFields, props.id),
                  convert_urls: false,
                }}
                onEditorChange={handleEditorChange}
              />
            </div>
          </PermissionsGate>
        </div>
      </div>

      <div className="col-auto">
        <CloneBlockButton onDuplicate={props.onDuplicate} enabled={hasPermission} />
      </div>

      <div className="col-auto">
        <DeleteButton onDelete={props.onDelete} />
      </div>
    </div>
  );
};

export default memo(ScriptBlockSay, () => true);
