import { useAssistantContext } from "@/contexts/AssistantContext";

const useContents = () => {
  const { assistant } = useAssistantContext();

  const limitCharacters = _.get(assistant, "character_limit", 400_000);

  const activeKnowledgeBases = _.get(assistant, "active_knowledge_bases", []);
  const consumedCharacters = _.sumBy(activeKnowledgeBases, (base) => base.character_count) || 0;

  const isCloseToLimit = consumedCharacters > (0.8 * limitCharacters);
  const isReachingLimit = consumedCharacters >= limitCharacters;

  return {
    consumedCharacters,
    limitCharacters,
    isCloseToLimit,
    isReachingLimit,
  };
};

export default useContents;
