import { OverlayTrigger, Popover } from "react-bootstrap"

import parse from "html-react-parser";

import * as S from "./styles";

const CountQuestions = ({ assistant }) => {
  if (!assistant.is_full_configured) return null;

  const {
    count_done: countDone,
    count_not_found: countNotFound,
    count_all: countTotal,
  } = assistant.messages_status;

  return (
    <OverlayTrigger
      overlay={
        <Popover>
          <Popover.Body>
            <div className="mb-1">
              {parse(I18n.t("views.leadster_ai.page.home.questions_count.done_html", { count: countDone }))}
            </div>
            <div>
              {parse(I18n.t("views.leadster_ai.page.home.questions_count.not_found_html", { count: countNotFound }))}
            </div>
          </Popover.Body>
        </Popover>
      }
    >
      <S.QuestionsCount>
        {I18n.t("views.leadster_ai.page.home.questions_count.total", { count: countTotal })}
      </S.QuestionsCount>
    </OverlayTrigger>
  );
};

export default CountQuestions;
