import PropTypes from "prop-types";

import ReactPortal from "@/components/ReactPortal";

import { Modal, Button, Image } from "react-bootstrap";
import trialAgainImg from "@/../assets/images/ai_assistant/modal-confirm.png";

import axios from "@/packs/axios";

const StartNewTrialModal = ({ show = false, onClose }) => {
  const handleClose = () => {
    onClose();
  }

  const startTrial = () => {
    axios
      .post("/ai/leadster/start-trial")
      .then(() => {
        window.location.href = "/ai/leadster/assistants";
      })
      .catch(() => {
        swal(I18n.t("request_feedback_alert.error.title"), I18n.t("request_feedback_alert.error.message"), "error");
      });
  };

  return (
    <ReactPortal>
      <Modal show={show} className="modal-blur" backdrop="static" centered onHide={handleClose}>
        <Modal.Header className="d-none" />

        <Modal.Body className="text-center py-3 mt-2">
          <div className="mb-3">
            <Image src={trialAgainImg} fluid />
          </div>

          <div className="fs-2 fw-bold mb-3">
            {I18n.t("views.leadster_ai.page.response_limit.allowed_trial.modal.text_1")}
          </div>

          <p className="mb-0">
            <b>{I18n.t("views.leadster_ai.page.response_limit.allowed_trial.modal.text_2")}</b>
          </p>
          <p>{I18n.t("views.leadster_ai.page.response_limit.allowed_trial.modal.text_3")}</p>
        </Modal.Body>

        <Modal.Footer className="border-top py-2">
          <Button className="me-auto btn btn-outline-secondary" onClick={handleClose}>
            {I18n.t("shared.actions.close")}
          </Button>

          <Button className="btn btn-primary" onClick={startTrial}>
            {I18n.t("views.leadster_ai.page.response_limit.allowed_trial.modal.button_label")}
          </Button>
        </Modal.Footer>
      </Modal>
    </ReactPortal>
  );
};

StartNewTrialModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default StartNewTrialModal;
