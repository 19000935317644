import { Button, Dropdown, Stack } from "react-bootstrap"

import NotAllowedOverlay from "./NotAllowedOverlay";
import AddToFlowButton from "./AddToFlowButton";
import CountQuestions from "./CountQuestions";
import AlertHandler from "./AlertHandler";

import { useHistory, useRouteMatch } from "react-router-dom";
import { can } from "@/permissions-provider";
import { classBinding } from '@/utils/helpers';

import * as S from "./styles";

const AssistantItem = ({ assistant, onClickRemoveAssistant }) => {
  const hasPermission = can({ action: "manage", subject: "leadster_ai_plugin" });

  let { url } = useRouteMatch();
  const history = useHistory();

  const handleNormalizeUrl = (url) => {
    return url.replace(/\/$/, "");
  };

  const handleClickRemoveAssistent = () => {
    onClickRemoveAssistant(assistant);
    MixpanelService.track("leadster_ai_remove_assistant");
  };

  const handleClickEditAssistant = () => {
    if (!hasPermission) return;

    history.push(`${handleNormalizeUrl(url)}/assistants/${assistant.id}`);
    MixpanelService.track("leadster_ai_edit_assistant");
  };

  return (
    <>
      <div className="card">
        <div className="card-body">
          <Stack direction="horizontal" gap={3}>
            <div className="d-flex align-items-center">
              <span
                className={classBinding({
                  "bg-success": assistant.is_full_configured,
                  "bg-warning": !assistant.is_full_configured,
                }, "d-inline-block rounded-circle bg-gradient avatar avatar-rounded avatar-sm")}
              />
            </div>

            <div className="fw-bold">
              {assistant.name}
            </div>

            <div className="ms-auto d-flex align-items-center gap-2">
              <CountQuestions assistant={assistant} />
              <AddToFlowButton assistant={assistant} />

              <Button
                variant="outline-primary"
                onClick={() => history.push(`${handleNormalizeUrl(url)}/analytics/${assistant.id}/dashboard`)}
              >
                <i className="ti ti-chart-dots icon" />
                {I18n.t("general.actions.analytics")}
              </Button>

              <NotAllowedOverlay enabled={!hasPermission}>
                <Button
                  variant="outline-primary"
                  disabled={!hasPermission}
                  onClick={handleClickEditAssistant}
                >
                  <i className="ti ti-user-edit icon" />
                  {I18n.t("shared.actions.edit")}
                </Button>
              </NotAllowedOverlay>

              <S.DropdownWithoutToogle>
                <Dropdown.Toggle className="btn-ghost-light btn-icon text-dark border-0" >
                  <i className="ti ti-dots-vertical" />
                </Dropdown.Toggle>

                <Dropdown.Menu align="start">
                  <Dropdown.Item
                    className="d-flex align-items-center text-danger gap-2"
                    onClick={handleClickRemoveAssistent}
                  >
                    <i className="ti ti-trash-x icon" />
                    {I18n.t("views.leadster_ai.page.home.assistant_excluded")}
                  </Dropdown.Item>
                </Dropdown.Menu>
              </S.DropdownWithoutToogle>
            </div>
          </Stack>

          <AlertHandler assistant={assistant} />
        </div>
      </div>
    </>
  );
};

export default AssistantItem;
