import React from "react";

import useCurrentUserStore from "@/store/currentUserStore";

import parse from "html-react-parser";

const ButtonAction = ({ link, candidateUpsell }) => {
  const { isNational } = useCurrentUserStore((state) => state.currentUser);

  if (isNational) {
    return candidateUpsell ? (
      <a href="/upsell" className="btn btn-success text-uppercase">
        {parse(I18n.t("views.shared.alerts.see_recommended_plans"))}
      </a>
    ) : (
      <a href={link} className="btn btn-success text-uppercase">
        {parse(I18n.t("views.shared.alerts.talk_to_team"))}
      </a>
    );
  }

  return candidateUpsell ? (
    <a href="/upsell" className="btn btn-success text-uppercase">
      {parse(I18n.t("views.shared.alerts.see_recommended_plans"))}
    </a>
  ) : (
    <button
      type="button"
      className="btn btn-success text-uppercase contact-tidio"
      data-tidio-message={I18n.t("shared.tidio.subscription_request_message")}
    >
      {parse(I18n.t("views.shared.alerts.talk_to_team"))}
    </button>
  );
};

export default ButtonAction;
