import { useRef, useState } from "react";

import CloneBlockButton from "./ScriptEditorParts/CloneBlockButton";
import DeleteButton from "./ScriptEditorParts/DeleteButton";

import { can } from "@/permissions-provider";

import parse from "html-react-parser";
import swal from 'sweetalert';

import imgJivoChatLogo from '../../../assets/images/jivochat.png';
import imgLeadsterAndJivo from '../../../assets/images/jivomaisneuro.png';

const PERMISSION_SCOPE = Object.freeze({
  action: 'manage',
  subject: 'script_flow'
});

const ScriptBlockJivochat = (props) => {
  const hasPermission = can(PERMISSION_SCOPE);

  const modalRef = useRef();
  const jivoModalRef = useRef();
  const neurologicJivoModalRef = useRef();
  const jivoIdRef = useRef();
  const jivoUrlRef = useRef();
  const jivoEmailRef = useRef();
  const jivoPasswordRef = useRef();
  const jivoUserRef = useRef();
  const contactNameRef = useRef();
  const contactEmailRef = useRef();
  const contactPhoneRef = useRef();
  const contactDescriptionRef = useRef();
  const gaCategoryRef = useRef();
  const gaActionRef = useRef();
  const gaTagIdRef = useRef();

  const [jivoFormType, setJivoFormType] = useState('create_account')

  const definition = (def) => {
    if (!hasPermission) return;

    const mergedDef = Object.assign(props.definition, def);
    mergedDef.say = generateMessage(mergedDef);
    props.setDefinition(mergedDef);
  }

  const openJivoSelectModal = (e) => {
    e.preventDefault();
    if (!hasPermission) return;

    $(neurologicJivoModalRef.current).modal('show');
  }

  const openJivoFormModal = (type) => {
    $(neurologicJivoModalRef.current).modal('hide');

    if (type == 'login_account') {
      $(jivoPasswordRef.current).val('');
    } else{
      $(jivoPasswordRef.current).val(generatePassword());
    }

    setJivoFormType(type);
    $(jivoModalRef.current).modal('show');
  }

  const update = (field) => {
    const say = $(field).val();
    definition({ botMessage: say });
  }

  const generateMessage = (def) => {
    return (def.botMessage || "") + generateButton(def);
  }

  const generateButton = (def) => {
    const style = `
      background: #455a64;
      color: white;
      padding: 1em;
      border-radius: 0.5em;
      box-shadow: 0 6px 12px rgba(0,0,0,0.2);
      font-weight: bold;
      margin-top: 1em;
    `;

    return `
      <img src="data:image/gif;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=" onload="function getLeadData(name){try{let lead_data = window.neurolead.dataQueue.find((e) => e.key == name);if(lead_data){return lead_data.value;}else{return '';}}catch(e){return ''}};window.jivo_onLoadCallback = function(){window.neurolead.close();window.neurolead.hideAvatar();jivo_api.setContactInfo({client_name: getLeadData('${def.contactName}'),email: getLeadData('${def.contactEmail}'),phone: '+55 ' + getLeadData('${def.contactPhone}'),description: getLeadData('${def.contactDescription}')});jivo_api.open();}" style=" width: 1px;height: 1px;" />
      <style>.nrl-jivo-button-loader {border: 3px solid #ffffff;border-top: 3px solid #65c059;border-radius: 50%;width: 15px;height: 15px;animation: spin 0.5s linear infinite;}@keyframes spin{0% { transform: rotate(0deg); }100% { transform: rotate(360deg);}}</style>
      <div class="nrl-jivo-button-loader" style="display: none"></div>
      <a href="javascript:void(0)" class="nrl-jivo-button-text" onClick="document.getElementsByClassName('nrl-jivo-button-text')[0].style.display = 'none';document.getElementsByClassName('nrl-jivo-button-loader')[0].style.display = 'block';var imported = document.createElement('script');imported.src = '//code.jivosite.com/widget/${def.jivoId}';imported.setAttribute('type', 'text/javascript');imported.async = true;document.head.appendChild(imported);" style="text-decoration: none">
        <div style="${style}">
          <span>
            <img src="https://cdn.leadster.com.br/neurolead/img/jivo_icon.svg" style="width: 2em; height: 2em; margin-top: -0.25em; float: left;"/>
            &nbsp;${I18n.t("views.manage_flows.script_editor.talk_to_attendant")}
          </span>
        </div>
      </a>`;
  }

  const updateJivoId = (eljivoId) => {
    const jivoId = $(eljivoId).val();

    if (/^[a-zA-Z0-9]+$/.test(jivoId)) {
      definition({ jivoId: jivoId });
    } else {
      definition({ jivoId: '' });
      swal(I18n.t("views.manage_flows.script_editor.an_error_has_occurred"), I18n.t("views.manage_flows.script_editor.invalid_jivochat_channel_id_field"), 'error');
    }
  }

  const saveSettings = () => {
    if (!hasPermission) return;
    $(modalRef.current).modal('hide');

    const gaCategory = $(gaCategoryRef.current).val();
    const gaAction = $(gaActionRef.current).val();
    const gaTagId = $(gaTagIdRef.current).val();

    definition({
      ...props.definition,
      gaCategory: gaCategory,
      gaAction: gaAction,
      gaTagId: gaTagId,
    });
  }

  const saveJivoSettings = ()=> {
    const jivoUser = $(jivoUserRef.current).val();
    const jivoEmail = $(jivoEmailRef.current).val();
    const jivoPassword = $(jivoPasswordRef.current).val();
    const jivoUrl = $(jivoUrlRef.current).val();

    if ((jivoFormType == 'create_account' && (jivoUser == '' || jivoEmail == '' || jivoPassword == '' || jivoUrl == '')) ||
      (jivoFormType == 'login_account' && (jivoEmail == '' || jivoPassword == '' ))) {
      swal(I18n.t("views.manage_flows.script_editor.an_error_has_occurred"), I18n.t("views.manage_flows.script_editor.all_fields_must_be_filled"), 'error');
    } else {
      $(jivoModalRef.current).modal('hide');
      $.ajaxSetup({
        headers: {
          'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
        }
      });

      $.ajax({
        url: '/create_jivo_account',
        data: JSON.stringify({jivoUser:jivoUser, jivoEmail:jivoEmail, jivoPassword:jivoPassword,jivoUrl:jivoUrl }),
        contentType: "application/json",
        type: "POST"
      }).done((data, status, xhr) => {
        $(jivoIdRef.current).val(data.jivo_channel_id)

        if (jivoFormType == 'create_account') {
          let createSuccessMessage = I18n.t("views.manage_flows.script_editor.you_will_receive_an_email_from_the_jivochat_team");
          createSuccessMessage += I18n.t("views.manage_flows.script_editor.we_take_advantage_of_your_registration_and_fill_in_the_field");

          swal(I18n.t("views.manage_flows.script_editor.account_created_successfully"), createSuccessMessage, 'success');
        } else {
          let loggedSuccessMessage = I18n.t("views.manage_flows.script_editor.we_take_advantage_of_your_login_and_fill_in_the_field")
          swal(I18n.t("views.manage_flows.script_editor.successfully_logged_in"), loggedSuccessMessage, 'success');
        }
      }).fail((data, status, xhr) => {
        swal(I18n.t("views.manage_flows.script_editor.an_error_has_occurred"), data.responseJSON.error, 'error');
      });
    }
  }

  const updateContactName = (event)=> {
    definition({ contactName: event });
  }

  const updateContactEmail = (event)=> {
    definition({ contactEmail: event });
  }

  const updateContactPhone = (event)=> {
    definition({ contactPhone: event });
  }

  const updateContactDescription = (event)=> {
    definition({ contactDescription: event });
  }

  const generatePassword = () => {
    let pass = Math.random().toString(36).slice(2);
    pass += Math.random().toString(36).slice(2).toUpperCase();
    return pass.split('').sort(function(){return 0.5-Math.random()}).join('');
  }

  const copyMeToClipboard = () => {
    let jivoPassword = $(jivoPasswordRef.current).val();

    navigator.clipboard.writeText(jivoPassword).then(function() {
      swal(I18n.t("views.manage_flows.script_editor.copied"), I18n.t("views.manage_flows.script_editor.value_copied_to_clipboard"), 'success');
    }, function() {
      swal(I18n.t("views.manage_flows.script_editor.did_not_copy"), I18n.t("views.manage_flows.script_editor.copy_didnt_work"), 'error');
    });
  }

  let vars = [];
  props.mapAttributesName(props.getFullTree(), vars);

  return <>
    <div className="mb-3">
      <a href="#" onClick={openJivoSelectModal}>
        <div className="blob"></div>
        {I18n.t("views.manage_flows.script_editor.click_here_to_link_jivo_chat_with_leadster")}
      </a>
    </div>

    <div className="row gx-2 mb-3">
      <div className="col">
        <div className="input-group">
          <button type='button'
            className='btn btn-primary'
            onClick={openJivoSelectModal}
            data-bs-toggle="tooltip"
            title={I18n.t("views.manage_flows.script_editor.click_here_to_edit_settings")}
          >
            <img src={imgJivoChatLogo} width={20}height={20} className="me-2" />
            {I18n.t("views.manage_flows.script_editor.jivochat")}
          </button>

          <input
            type="text"
            className="form-control"
            placeholder={I18n.t("views.manage_flows.script_editor.type_a_message_here")}
            disabled={!hasPermission}
            onChange={e => update(e.target)}
            defaultValue={props.definition.botMessage || ""}
          />
        </div>
      </div>

      <div className="col-auto">
        <CloneBlockButton onDuplicate={props.onDuplicate} enabled={hasPermission} />
      </div>

      <div className="col-auto">
        <DeleteButton onDelete={props.onDelete} />
      </div>
    </div>

    <div className="row g-3">
      <div className="col-12 col-md-6 form-group">
        <div className="form-label">
          {I18n.t("views.manage_flows.script_editor.lead_name")}
        </div>

        <select
          defaultValue={props.definition.contactName || ""}
          className="form-select"
          ref={contactNameRef}
          disabled={!hasPermission}
          onChange={e => updateContactName(e.target.value)}
        >
          <option value="">{I18n.t("views.manage_flows.script_editor.select")}</option>
          {vars.map((e) => <option value={e} key={e}>{e}</option>)}
        </select>
      </div>

      <div className="col-12 col-md-6 form-group">
        <div className="form-label">
          {I18n.t("views.manage_flows.script_editor.lead_email")}
        </div>

        <select
          defaultValue={props.definition.contactEmail || ""}
          className="form-select"
          ref={contactEmailRef}
          disabled={!hasPermission}
          onChange={e => updateContactEmail(e.target.value)}
        >
          <option value="">{I18n.t("views.manage_flows.script_editor.select")}</option>
          {vars.map((e) => <option value={e} key={e}>{e}</option>)}
        </select>
      </div>

      <div className="col-12 col-md-6">
        <div className="form-label">
          {I18n.t("views.manage_flows.script_editor.lead_phone")}
        </div>

        <select
          defaultValue={props.definition.contactPhone || ""}
          className="form-select"
          ref={contactPhoneRef}
          disabled={!hasPermission}
          onChange={e => updateContactPhone(e.target.value)}
        >
          <option value="">{I18n.t("views.manage_flows.script_editor.select")}</option>
          {vars.map((e) => <option value={e} key={e}>{e}</option>)}
        </select>
      </div>

      <div className="col-12 col-md-6">
        <div className="form-label">
          {I18n.t("views.manage_flows.script_editor.lead_observation")}
        </div>

        <select
          defaultValue={props.definition.contactDescription || ""}
          className="form-select"
          ref={contactDescriptionRef}
          disabled={!hasPermission}
          onChange={e => updateContactDescription(e.target.value)}
        >
          <option value="">{I18n.t("views.manage_flows.script_editor.select")}</option>
          {vars.map((e) => <option value={e} key={e}>{e}</option>)}
        </select>
      </div>
    </div>

    <div className="mt-3">
      <label className="form-label">
        {I18n.t("views.manage_flows.script_editor.jivochat_channel_id")}
        <a
          href={I18n.t("shared.helpscout.integrate_jivochat")}
          className='form-help ms-2'
          target="_blank"
          data-bs-toggle="tooltip"
          title={I18n.t("views.manage_flows.script_editor.see_how_to_find_this_information")}
        >
          <i className="fa fa-info" aria-hidden="true"></i>
        </a>
      </label>

      <input
        type="text"
        className="form-control col-6"
        onChange={e => updateJivoId(e.target)}
        maxLength={15}
        ref={jivoIdRef}
        disabled={!hasPermission}
        defaultValue={props.definition.jivoId || ""}
      />
    </div>

    <div ref={modalRef} className="modal modal-blur fade" tabIndex="-1" role="dialog">
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">{I18n.t("views.manage_flows.script_editor.settings")}</h5>
            <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>

          <div className="modal-body">
            <div className="mb-4">
              <img src={require('../../../assets/images/ga_logo.png')} style={{ height: '1.25em' }} />
            </div>

            <div className="form-inline">
              <div className="form-group my-2">
                <label>{I18n.t("views.manage_flows.script_editor.category")}</label>
                <input type="text" className="form-control ms-3" ref={gaCategoryRef} defaultValue={props.definition.gaCategory} />
              </div>

              <div className="form-group my-2">
                <label>{I18n.t("views.manage_flows.script_editor.action")}</label>
                <input type="text" className="form-control ms-3" ref={gaActionRef} defaultValue={props.definition.gaAction} />
              </div>

              <div className="form-group my-2">
                <label>{I18n.t("views.manage_flows.script_editor.tag_id")}</label>
                <input type="text" className="form-control ms-3" ref={gaTagIdRef}
                  placeholder={I18n.t("views.manage_flows.script_editor.optional")} defaultValue={props.definition.gaTagId}
                />
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-primary" onClick={saveSettings}>
              {I18n.t("general.actions.ok")}
            </button>
          </div>
        </div>
      </div>
    </div>

    <div ref={neurologicJivoModalRef} className="modal modal-blur fade" tabIndex="-1" role="dialog">
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          <div className="modal-body text-center">
            <img src={imgLeadsterAndJivo} height={40} className="mb-3"/>

            <div className="h3 mb-4">
              {parse(I18n.t("views.manage_flows.script_editor.to_link_jivo_chat_with_leadster_html"))}
            </div>

            <button
              type="button"
              onClick={(e) => {e.preventDefault();openJivoFormModal('login_account')}}
              className="btn btn-success"
            >
              {I18n.t("views.manage_flows.script_editor.log_into_your_jivo_account")}
            </button>

            <div className="hr-text my-3">
              <span>{I18n.t("views.manage_flows.script_editor.where")}</span>
            </div>

            <button
              type="button"
              onClick={(e) => {e.preventDefault();openJivoFormModal('create_account')}}
              className="btn btn-primary"
            >
              {I18n.t("views.manage_flows.script_editor.create_a_new_jivo_account")}
            </button>
          </div>
        </div>
      </div>
    </div>

    <div ref={jivoModalRef} className="modal modal-blur fade" tabIndex="-1" role="dialog">
    <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            <h5 className="modal-title">
              <img src={imgJivoChatLogo} height={25} className='me-2'/>
              {jivoFormType == 'login_account' ? I18n.t("views.manage_flows.script_editor.log_into_your_jivo_account") : I18n.t("views.manage_flows.script_editor.create_a_new_jivo_account")}
            </h5>
          </div>

          <div className="modal-body">
            <div className="mb-3" style={jivoFormType == 'login_account' ? {display: 'none'} : {}}>
              <label className="form-label">{I18n.t("views.manage_flows.script_editor.user_name")}</label>
              <input type="text" className="form-control" ref={jivoUserRef} defaultValue={props.additionalData.user_name}/>
            </div>

            <div className="mb-3" style={jivoFormType == 'login_account' ? {display: 'none'} : {}}>
              <label className="form-label">{I18n.t("views.manage_flows.script_editor.website_url")}</label>
              <input type="text" className="form-control" ref={jivoUrlRef} defaultValue={props.additionalData.website}/>
            </div>

            <div className="mb-3">
              <label className="form-label">{I18n.t("views.manage_flows.script_editor.email")}</label>
              <input type="text" className="form-control" ref={jivoEmailRef} defaultValue={jivoFormType == 'login_account' ? 'te' : props.additionalData.user_email}/>
            </div>

            <div className="mb-3">
              <label className="form-label">{I18n.t("views.manage_flows.script_editor.password")}</label>
              <div className="input-group">
                <input
                  type={jivoFormType == 'login_account' ? 'password' : 'text'}
                  className="form-control"
                  ref={jivoPasswordRef}
                  autoComplete='new-password'
                  defaultValue={jivoFormType == 'login_account' ? '' : generatePassword()}
                />

                {jivoFormType == 'create_account' &&
                  <button
                    className="btn btn-success"
                    title={I18n.t("views.manage_flows.script_editor.copy_to_clipboard")}
                    type="button"
                    onClick={() => copyMeToClipboard()}
                  >
                    {I18n.t("views.manage_flows.script_editor.copy")}
                  </button>
                }
              </div>
            </div>

            {jivoFormType == 'create_account' &&
              <div className="text-danger">
                <i className="ti ti-info-circle icon me-1"></i>
                {I18n.t("views.manage_flows.script_editor.save_this_password_or_enter_one_of_your_own")}
              </div>
            }
          </div>

          <div className="modal-footer">
            <button type="button" className="btn btn-primary" onClick={saveJivoSettings}>
              {jivoFormType == 'login_account' ? I18n.t("views.manage_flows.script_editor.log_into") : I18n.t("views.manage_flows.script_editor.create_an_account")}
            </button>
          </div>
        </div>
      </div>
    </div>
  </>
}

export default ScriptBlockJivochat;
