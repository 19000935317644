import { Button, Card, OverlayTrigger, Popover } from "react-bootstrap";

import { useHistory, useRouteMatch } from "react-router-dom";
import { can } from "@/permissions-provider";
import useCurrentUserStore from "@/store/currentUserStore";

import CreateNewAssistantImg from "@/../assets/images/ai_assistant/create-new-assistant.png";

const CreateNewAssistant = () => {
  const hasPermission = can({ action: "manage", subject: "leadster_ai_plugin" });
  const { plugins: { leadsterAi: { allowedNewTrial } } } = useCurrentUserStore((state) => state.currentUser);

  let { url } = useRouteMatch();
  const history = useHistory();

  const buttonLabel = () => {
    if (allowedNewTrial) return I18n.t("views.leadster_ai.page.home.allowed_new_trial");

    return I18n.t("views.leadster_ai.page.home.create_new_assistant");
  }

  const popoverLabel = () => {
    if (allowedNewTrial) return I18n.t("views.leadster_ai.page.home.start_new_trial_invite");

    return I18n.t("views.leadster_ai.page.home.not_allowed_create");
  }

  const handleNormalizeUrl = (url) => {
    return url.replace(/\/$/, "");
  };

  const handleClickButton = () => {
    if (!hasPermission) return;

    history.push(`${handleNormalizeUrl(url)}/assistants`);
    MixpanelService.track("leadster_ai_new_assistant");
  };

  return (
    <Card className="border-none">
      <Card.Body>
        <Card.Img src={CreateNewAssistantImg} />

        <div className="d-grid gap-2 pt-3">
          <OverlayTrigger
            trigger={!hasPermission ? ["hover", "focus"] : false}
            overlay={
              <Popover>
                <Popover.Body>{popoverLabel()}</Popover.Body>
              </Popover>
            }
          >
            <Button
              variant="outline-primary"
              className="fs-3"
              size="lg"
              onClick={handleClickButton}
              disabled={!hasPermission}
            >
              <i className="ti ti-plus me-2" />
              {buttonLabel()}
            </Button>
          </OverlayTrigger>
        </div>
      </Card.Body>
    </Card>
  );
};

export default CreateNewAssistant;
