import dayjs from "@/packs/dayjs";

class CalculatorUtils {
  static calcPricebyPeriod(price, period) {
    if (period === 'monthly') return price;

    const installments = {
      "quarterly": 3,
      "semiannual": 6,
      "yearly": 12
    }

    return (price / installments[period])
  }

  static calcPriceByInstallments (price, instalments) {
    return (price / instalments)
  }

  static calcAdittionalDays (
    date,
    days,
    formater = I18n.t("js.formats.default.date")
  ) {
    let result = dayjs(date);
    result = result.add(days, 'day');
    return result.format(formater);
  }

  static calcDaysByPeriod (period) {
    const monthsAmount = {
      "monthly": 30,
      "quarterly": 30 * 3,
      "semiannual": 30 * 6,
      "yearly": 30 * 12
    }

    return monthsAmount[period]
  }
}

export default CalculatorUtils;
