import React from "react";

import Step1 from "./Step1";
import Step2 from "./Step2";

import sweetAlert from "sweetalert";
import axios from "../../packs/axios";

import CurrencyFormater from 'react-currency-format';
import { buildWhatsAppLink } from "@/utils/whatsapp_helpers";
import BackLimitedVersion from "../whatsapp/BackLimitedVersion"
import parse from "html-react-parser"

export default class Index extends React.Component {
  state = {
    billingPeriod: 'month',
    step: 1,
    activeFaq: 0,
    plan: this.props.plan,
    coupons: [],
    updated_price: null
  }

  getWhatsappMessage = () => {
    return I18n.t("billing_whatsapp.text_01");
  }

  getPricePerLead = () => {
    let total = parseInt(this.props.leads_count_last_period)

    if (total !== 0) {
      return (this.state.plan.price / (total * 2))
    }

    return 0.00;
  }

  changeBillingPeriod = () => {
    if (this.state.coupons.length > 0) {
      sweetAlert({
        title: I18n.t("billing.coupon.are_you_sure"),
        text: I18n.t("billing.coupon.change_the_billing_method"),
        icon: "warning",
        buttons: [I18n.t("general.actions.cancel"), I18n.t("general.actions.ok"), I18n.t("general.actions.change")],
        dangerMode: false,
      })
        .then((willDelete) => {
          if (willDelete) {
            this.applyBillingPeriodChange()
          }
        });
    } else {
      this.applyBillingPeriodChange()
    }
  }

  applyBillingPeriodChange = () => {
    self = this

    let newPeriod = "month"

    if (this.state.billingPeriod === "month") {
      newPeriod = "year";
    }

    let plan_price_id = this.props.billing_periods[newPeriod];

    axios.get("/payments/change_plan_modality?plan_price_id=" + plan_price_id).then(function (response) {
      self.setState({ plan: response.data.plan });
    });

    this.setState({ billingPeriod: newPeriod, coupons: [], updated_price: null })
  }

  setStep = (step) => {
    this.setState({ step: step })
  }

  sliderWidth = (limit_users) => {
    let width = (limit_users * 100) / 100000;

    if (width > 80) {
      return 80
    } else if (width < 20) {
      return 20
    } else {
      return width
    }
  }

  applyCoupon = (e) => {
    let coupon = e.val();
    let parent = this;

    if (coupon) {
      axios.post("/payments/calc_coupon", {
        coupon: coupon,
        company_id: parent.props.company.id,
        plan_price_id: parent.state.plan.id,
      })
        .then((response) => {
          parent.setState({ coupons: response.data.applied_coupons, updated_price: response.data.new_price });
          sweetAlert("", I18n.t("billing.coupon.coupon_applied_successfully"), "success").then(r => {});
        })
        .catch((error) => {
          if (error.response) {
            parent.setState({ coupons: error.response.data.applied_coupons, updated_price: error.response.data.new_price });
            sweetAlert("", error.response.data.error, "error").then(r => {});
          }
        });
    }

    $(e).val("");
    $(e).focus();
  }


  render() {
    return (
      <div className="row billing">
        <div className="col-md-12">
          <h1 className="text-info">{I18n.t("billing.title").replace("%{many}", this.props.leads_count_last_period > 0 ? (this.props.leads_count_last_period) : 'muitos')}</h1>
          <span className="subtitle">{I18n.t("billing.description").replace("%{company_name}", this.props.company['name'])}</span>

          <div className="slidecontainer">
            <div className="line-gray">
              <div className="line-blue" style={{ width: `${this.sliderWidth(this.state.plan.access_limit)}%` }}>
                <div className="slider-select">
                  <div className="tooltip">
                    <span className="tooltiptext">{`${this.state.plan.access_limit} Visitas por mês`}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-5 col-sm-7">
          <strong className="box-subtitle">{I18n.t("billing.transparent_plans.title")}</strong>
          <span className="block">{I18n.t("billing.transparent_plans.analysis_based_on_your_result")}</span>
          <ul className='list-info'>
            <li>{I18n.t("billing.transparent_plans.number_of_visits_per_month.text01")} <span className="text-info">{I18n.t("billing.transparent_plans.number_of_visits_per_month.text02").replace("%{accesses_count}", this.props.accesses_count)}</span></li>
            <li>{I18n.t("billing.transparent_plans.estimated_lead_generation_in_one_month.text01")} <span className="text-info">{I18n.t("billing.transparent_plans.estimated_lead_generation_in_one_month.text02").replace("%{leads_count}", this.props.leads_count_last_period)}</span></li>
            {!this.props.exceeded_access && (
              <li>{I18n.t("billing.transparent_plans.estimate_price_per_lead_generated_with_leadster")}&nbsp;
                <span className="text-info">
                  {
                    <CurrencyFormater value={this.getPricePerLead()}
                      displayType={'text'}
                      thousandSeparator={'.'}
                      prefix={'R$'}
                      decimalSeparator={','}
                      decimalScale={2} />
                  }
                </span>
              </li>
            )}
          </ul>

          {this.props.allow_return_to_limited_account && (
            <div className="card card-sm p-2 shadow">
              <div className="card-body">
                <div className="fs-2 fw-bold mb-3 text-black">
                  { I18n.t("billing_whatsapp.text_02")}
                </div>

                <p>{ I18n.t("billing_whatsapp.text_03") }</p>
                <p>{ I18n.t("billing_whatsapp.text_04") }</p>

                <BackLimitedVersion allowToBack={this.props.allow_return_to_limited_account}/>
              </div>
            </div>
          )}

          <h4 className='box-subtitle'>{I18n.t("billing.common_questions.title")}</h4>
          <ul className="faq">
            <li onClick={event => this.state.activeFaq === 1 ? this.setState({ activeFaq: 0 }) : this.setState({ activeFaq: 1 })}>
              {I18n.t("billing.common_questions.question01.title")}
              {this.state.activeFaq === 1 &&
                <i className='ti ti-minus' />
              }
              {this.state.activeFaq !== 1 &&
                <i className='ti ti-plus' />
              }
              <div className={`block-hide ${this.state.activeFaq === 1 ? 'show' : ''}`}>
                &nbsp;<br />
                {I18n.t("billing.common_questions.question01.description")}
              </div>
            </li>
            <li onClick={event => this.state.activeFaq === 2 ? this.setState({ activeFaq: 0 }) : this.setState({ activeFaq: 2 })}>
              {I18n.t("billing.common_questions.question02.title")}
              {this.state.activeFaq === 2 &&
                <i className='ti ti-minus' />
              }
              {this.state.activeFaq !== 2 &&
                <i className='ti ti-plus' />
              }
              <div className={`block-hide ${this.state.activeFaq === 2 ? 'show' : ''}`}>
                &nbsp;<br />
                {I18n.t("billing.common_questions.question02.description")}
              </div>
            </li>
            <li onClick={event => this.state.activeFaq === 3 ? this.setState({ activeFaq: 0 }) : this.setState({ activeFaq: 3 })}>
              {I18n.t("billing.common_questions.question03.title")}
              {this.state.activeFaq === 3 &&
                <i className='ti ti-minus' />
              }
              {this.state.activeFaq !== 3 &&
                <i className='ti ti-plus' />
              }
              <div className={`block-hide ${this.state.activeFaq === 3 ? 'show' : ''}`}>
                &nbsp;<br />
                {I18n.t("billing.common_questions.question03.description")}
              </div>
            </li>
            <li onClick={event => this.state.activeFaq === 4 ? this.setState({ activeFaq: 0 }) : this.setState({ activeFaq: 4 })}>
              {I18n.t("billing.common_questions.question04.title")}
              {this.state.activeFaq === 4 &&
                <i className='ti ti-minus' />
              }
              {this.state.activeFaq !== 4 &&
                <i className='ti ti-plus' />
              }
              <div className={`block-hide ${this.state.activeFaq === 4 ? 'show' : ''}`}>
                &nbsp;<br />
                {I18n.t("billing.common_questions.question04.description")}
              </div>
            </li>
            <li onClick={event => this.state.activeFaq === 5 ? this.setState({ activeFaq: 0 }) : this.setState({ activeFaq: 5 })}>
              {I18n.t("billing.common_questions.question05.title")}
              {this.state.activeFaq === 5 &&
                <i className='ti ti-minus' />
              }
              {this.state.activeFaq !== 5 &&
                <i className='ti ti-plus' />
              }
              <div className={`block-hide ${this.state.activeFaq === 5 ? 'show' : ''}`}>
                &nbsp;<br />
                {I18n.t("billing.common_questions.question05.description")}
              </div>
            </li>
            <li onClick={event => this.state.activeFaq === 6 ? this.setState({ activeFaq: 0 }) : this.setState({ activeFaq: 6 })}>
              {I18n.t("billing.common_questions.question06.title")}
              {this.state.activeFaq === 6 &&
                <i className='ti ti-minus' />
              }
              {this.state.activeFaq !== 6 &&
                <i className='ti ti-plus' />
              }
              <div className={`block-hide ${this.state.activeFaq === 6 ? 'show' : ''}`}>
                &nbsp;<br />
                {I18n.t("billing.common_questions.question06.description")}
              </div>
            </li>
          </ul>
        </div>

        <div className="offset-sm-2 col-sm-5">
          <div className="card shadow mb-3">

            {this.props.exceeded_access && (
              <>
                <img src="/images/subscriptions/talk-to-seller.jpg" className="card-img-top mb-3" alt="Image"/>

                <div className="h2 text-black">
                  { I18n.t("billing_whatsapp.text_05") }  {this.props.is_whatsapp ? I18n.t("billing_whatsapp.text_06") : "" }?
                </div>

                <p>{ parse(I18n.t("billing_whatsapp.text_07.html")) }</p>

                <p>{ parse(I18n.t("billing_whatsapp.text_08.html")) }</p>

                <a
                  href={buildWhatsAppLink({
                    number: this.props.whatsapp_contact,
                    message: this.getWhatsappMessage(),
                    type: 'web',
                    mobileDevice:  this.props.is_mobile
                  })}
                  type="button"
                  className='btn btn-green fs-3'>
                  <i className="ti ti-brand-whatsapp icon" />
                  {I18n.t("billing.with_us_by_whatsapp.native_whatsapp")}
                </a>
              </>
            )}

            {!this.props.exceeded_access && (
              <>
                {this.state.step === 1 && <Step1
                  setStep={this.setStep}
                  changeBillingPeriod={this.changeBillingPeriod}
                  leadsCountLastPeriod={this.props.leads_count_last_period}
                  billingPeriod={this.state.billingPeriod}
                  plan={this.state.plan}
                  companyId={this.props.company.id}
                  agree_terms_pdf={this.props.agree_terms_pdf}
                />}

                {this.state.step === 2 && <Step2
                  companyId={this.props.company.id}
                  coupons={this.state.coupons}
                  showCoupon={true}
                  updated_price={this.state.updated_price}
                  applyCoupon={this.applyCoupon}
                  changeBillingPeriod={this.changeBillingPeriod}
                  plan={this.state.plan}
                  billingPeriod={this.state.billingPeriod}
                  vindi_public_token={this.props.vindi_public_token}
                  magic_key={this.props.magic_key}
                  railsEnv={this.props.railsEnv}
                />}

                <hr />

                <div className="text-center">
                  <div className='fs-4 fw-medium mb-3'>
                    {I18n.t("billing.with_us_by_whatsapp.title")}
                  </div>
                  <a
                    className="btn-whats"
                    href={buildWhatsAppLink({
                      number: this.props.whatsapp_contact,
                      message: this.getWhatsappMessage(),
                      type: 'web',
                      mobileDevice:  this.props.is_mobile
                    })}
                    target="_blank">
                    <img src="https://cdn.leadster.com.br/neurolead/img/wpp_logo.png" alt="WhatsApp" />
                    {I18n.t("billing.with_us_by_whatsapp.native_whatsapp")}
                  </a>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    );
  }
}
