import { useHistory } from "react-router-dom";
import { useState } from "react";

import { captureException } from "@sentry/react";
import axios from "@/packs/axios";

import { useUpsellContext } from "../../../context";
import { buildWhatsAppLink } from "@/utils/whatsapp_helpers";
import useAlertDefault from "@/components/shared/AlertDefault/hooks/useAlertDefault";
import useCurrentUserStore from "@/store/currentUserStore";

const useLogic = () => {
  const { firstName } = useCurrentUserStore((state) => state.currentUser);
  const history = useHistory();
  const { progressData, resetPaymentProgressData } = useUpsellContext();
  const [isLoadingSubmitUpsell, setIsLoadingSubmitUpsell] = useState(false);
  const { showAlert } = useAlertDefault((state) => state);

  const onSubmit = async () => {
    setIsLoadingSubmitUpsell(true);

    try {
      await axios.post("/upsell/upsell-plan",
        {
          plan_id: progressData.idPlan,
          notice_period_awareness: true,
          minimum_dead_line_science: true,
          installments: progressData.payment.installment,
          financial_email: progressData.financialEmail.value
        }
      );

      history.push("/upsell/order");
    }
    catch (err) {
      captureException(err);

      showAlert({
        icon: "ERROR",
        title: I18n.t("views.upsell.stepper.step_two.validates.alert_3.title"),
        description: I18n.t("views.upsell.stepper.step_two.validates.alert_3.description_html"),
        buttons: [
          {
            text: I18n.t("views.upsell.stepper.step_two.validates.alert_3.btn_whats"),
            action: () => {
              window.open(buildWhatsAppLink({
                number: '41991074307',
                message: I18n.t("views.upsell.stepper.step_two.validates.alert_3.message", {
                  user_name: firstName
                }),
                mobileDevice: navigator.userAgentData !== undefined && navigator.userAgentData.mobile
              }))
            }
          }
        ],
        closeButton: {
          text: I18n.t("shared.actions.cancel"),
          action: () => {
            history.push("/upsell/")
            resetPaymentProgressData();
          }
        }
      });
    }

    setIsLoadingSubmitUpsell(false);
  };

  return {
    data: {
      isLoadingSubmitUpsell
    },
    methods: {
      onSubmit
    }
  };
};

export default useLogic;
