import { useEffect, useRef } from "react";

import { useAssistantContext } from "@/contexts/AssistantContext";

import { captureException } from "@sentry/react";

import axios from "@/packs/axios";

const useWatchPendingContent = () => {
  const { assistant, removePendingBase, addActiveBase } = useAssistantContext();
  const timeoutRef = useRef();

  const analysePendingContent = async () => {
    const pendingBase = assistant.pending_knowledge_base;
    const isMultiUrlProcess = pendingBase.hasOwnProperty("multiple_url_process_status");
    const controllerPath = isMultiUrlProcess ? "multi_url_processes" : "knowledge_bases";

    try {
      const response = await axios.get(`/ai/${controllerPath}/${pendingBase.id}/?ts=${new Date().getTime()}`);
      const knowledgeBase = response.data.knowledge_base

      if (knowledgeBase.status === "pending") return;

      clearInterval(timeoutRef.current);
      removePendingBase();

      if (knowledgeBase.status === "done") {
        toastr.success(I18n.t("views.leadster_ai.page.add_content.alerts.success"));
        window.location.reload();
        return addActiveBase(knowledgeBase);
      }

      toastr.error(I18n.t("views.leadster_ai.page.add_content.alerts.error"));
    } catch (error) {
      captureException(error);
      clearInterval(timeoutRef.current);
    }
  };

  useEffect(() => {
    if (!_.get(assistant, 'pending_knowledge_base')) {
      return;
    }

    timeoutRef.current = setInterval(async () => {
      await analysePendingContent();
    }, 10000);

    return () => {
      clearInterval(timeoutRef.current);
    }
  }, [assistant]);
};

export default useWatchPendingContent;
